import { observable, action, computed } from 'mobx'

import PlaceService from '../services/PlaceService'

interface IGeneralItem {
  key: string
  value: any
}

class PlacePageStore {
  @action
  async getPlaceData(placeId: string) {
    try {
      const response = await PlaceService.getPlace(placeId)

      return this.formatResponse(response.data)
    } catch (error) {
      throw error
    }
  }

  formatResponse(data: any) {
    const wifiData = data.general.find((i: IGeneralItem) => i.key === 'wifi')
    const wifi = wifiData ? wifiData.value : undefined
    const addressData = data.general.find((i: IGeneralItem) => i.key === 'address')
    const address = addressData ? addressData.value : undefined
    const checkinData = data.general.find((i: IGeneralItem) => i.key === 'checkin')
    const checkin = checkinData ? checkinData.value : undefined
    const checkoutData = data.general.find((i: IGeneralItem) => i.key === 'checkout')
    const checkout = checkoutData ? checkoutData.value : undefined
    const contactData = data.general.find((i: IGeneralItem) => i.key === 'contact')
    const contact = contactData ? contactData.value : undefined

    const general = {wifi, address, checkin, checkout, contact}

    return {...data, general}
  }
}


const storeInstance = new PlacePageStore()
export default storeInstance
