import React from 'react'
import styled from 'styled-components'

import { media } from '../../../util/breakpoints'

function FeaturesBlock() {
  return (
    <Features>
    <ContainerInvertedOnMobile>
      <Block>
        <BlockImg alt="Páginas do hostli.com.br" src="/images/smartphone-images/hostli-7.png"/>
      </Block>
      <Block>
        <Title>O que é o Hostli?</Title>
        <Text>O Hostli é um sistema simples e eficiente que transforma todas as informações da sua hospedagem em um guia digital. É ideal para proprietários de imóveis de temporada, pousadas e pequenos hotéis.</Text>
      </Block>
    </ContainerInvertedOnMobile>
      <Container>
        <Block>
          <Title>Todas informações. Mesmo.</Title>
          <Text>A sua página no Hostli é onde seu hóspede pode encontrar todas informações necessárias. Contatos, endereço, Wi-Fi, Instruções de Check-In, Regras da Casa, Recomendações e muito mais. Tudo em um só lugar.</Text>
        </Block>
        <Block>
          <BlockImg alt="Painel do hostli.com.br" src="/images/smartphone-images/hostli-2.png"/>
        </Block>
      </Container>
      <ContainerInvertedOnMobile>
        <Block>
          <BlockImg alt="Páginas do hostli.com.br" src="/images/smartphone-images/hostli-3.png"/>
        </Block>
        <Block>
          <Title>Indique os melhores!</Title>
          <Text>Recomende os melhores lugares para visitar, comer e se divertir com sugestões pensadas por quem conhece a região.</Text>
        </Block>
      </ContainerInvertedOnMobile>
    </Features>
  )
}

const Features = styled.div`
  background-color: #f6f7fb;
  min-height: 400px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: 100vh;
  padding: 24px;
  flex-direction: column;

  ${media.tabMUp} {
    min-height: auto;
    flex-direction: row;
  }

  ${media.deskGUp} {
    padding: 24px 12%;
  }

  &:first-child {
    background-color: #f6f7fb;
  }

  &:last-child {
    padding-bottom: 72px;
  }
`

const ContainerInvertedOnMobile = styled(Container)`
  flex-direction: column-reverse;
`

const Block = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 100%;

  ${media.tabMUp} {
    max-width: 50%;
  }
`

const BlockImg = styled.img`
  max-width: 100%;
  max-height: 400px;
  padding: 8px;
`

const Title = styled.p`
  margin: 0;
  width: 100%;

  color: #ff4342;
  font-size: 48px;
  font-weight: 600;
  text-align: left;
  line-height: 52px;
`

const Text = styled.p`
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`

export default FeaturesBlock
